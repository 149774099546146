<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Advertisements',
  components: {}
}
</script>